<template>
  <b-row class="mt-1">
    <b-col align="left" cols="6">
      <b-row>
        <b-col>
          <label class="report-title"> {{ $t('views.marketing.report.top-content.title') }} </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="report-subtitle">{{ $t('views.marketing.report.top-content.subtitle') }}</label>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      v-if="campaign"
      align="right"
      cols="6"
    >
      <d-button
        v-if="(campaign.status === 'delivered' || campaign.status === 'sending' || campaign.status === 'pending')"
        text="general.actions.previous"
        icon="fa fa-arrow-left"
        icon-position="left"
        class="d-btn-sm btn d-btn-danger font-text-title"
        @on:button-click="$router.push({name: `${campaignName}_campaign`})"
      />
      <template v-else>
        <d-button
          text="views.marketing.report.top-content.edit-draft"
          class="d-btn-sm d-btn btn d-btn-default font-text-title mr-2"
          @on:button-click="updateStatus('draft')"
        />
        <d-button
          :text="saveButtonText"
          class="d-btn-sm d-btn btn d-btn-danger font-text-title"
          @on:button-click="updateStatus('pending')"
        />
      </template>
    </b-col>
  </b-row>
</template>
<script>
import {putCampaignStatus} from "../../../../api/doinsport/services/campaign";

export default {
  props: {
    campaign: {
      type: Object,
      default: () => {
      }
    },
    routeName: {
      type: String,
      default: null
    }
  },
  computed: {
    campaignId() {
      return this.$route.params.id;
    },
    updatePageName() {
      return `marketing_${this.campaignName}_update`;
    },
    campaignName() {
      return this.routeName.split('_')[1];
    },
    saveButtonText() {
      if (this.campaign) {
        if (this.campaign.programmingDate) {
          return 'general.actions.schedule';
        }
      }
      return 'general.actions.save';
    }
  },
  methods: {
    updateStatus(status) {
      putCampaignStatus(this.campaignName, {id: this.campaignId, status: status})
        .then(() => {
          const target = status === 'draft' ?  {name: this.updatePageName, params: {id: this.campaign.id}} : {name: `${this.campaignName}_campaign`};
          this.$router.push(target);
        })
      ;
    },
  }
}
</script>

<style lang="scss" scoped>
.report-title {
  font: normal normal 900 17px Avenir;
  letter-spacing: 0.63px;
  color: #333333;
}

.report-subtitle {
  font: normal 15px Avenir;
  letter-spacing: 0.63px;
  color: #333333;
}
</style>
